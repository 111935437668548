<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        <Comeback /> {{ $t('CourseBalanceSummary') }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="exportLoading"
          class="d-none d-md-block"
          :disabled="exportLoading"
          @click="exportExcel"
        >
          {{ $t('excel') }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          :loading="exportLoading"
          :disabled="exportLoading"
          icon
          fab
          outlined
          @click="exportExcel"
        >
          <v-icon>{{ mdiFileExcelOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <DateFilters
        :default-status="2"
        @onSendDate="addPayload"
      />
      <v-row class="px-2">
        <v-col
          cols="12"
          md="6"
          lg="4"
          class="py-0"
        >
          <v-text-field
            v-model="searchtext"
            dense
            outlined
            :label="`${$t('search')} (${$t('pressEnterForSearch')})`"
            @keypress.enter="addPayload(payload.start, payload.end)"
          >
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
          class="py-0"
        >
          <v-autocomplete
            v-model="customerGroupSelect"
            outlined
            dense
            :items="customerGroupList"
            item-text="customer_group_name"
            :label="$t('group_customers')"
            item-value="customer_group_id"
            @change="addPayload(payload.start, payload.end)"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.course_type`]="{ item }">
          <v-chip
            v-if="item.course_type"
            :color="item.course_type == $t('limited_time')?'primary':item.course_type == $t('limited_day')?'warning':'info'"
            :class="item.course_type==$t('limited_time')?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg primary--text`:item.course_type==$t('limited_day')?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg warning--text`:`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg info--text`"
            small
          >
            <v-icon
              small
              class="me-1"
            >
              {{ item.course_type == $t('limited_time') ?icons.mdiTimetable: item.course_type == $t('limited_day') ?icons.mdiClockOutline:icons.mdiPill }}
            </v-icon>
            {{ item.course_type }}
          </v-chip>
        </template>
        <template v-slot:[`item.balance`]="{ item }">
          <span
            v-if="item.course_type == $t('limited_drug')"
            class="primary--text"
            style="cursor: pointer"
            @click="
              showDetailDrugService(
                item.customer_id_pri,
                item.course_id_pri
              )
            "
          >
            {{ item.balance }}
          </span>
          <span v-else>
            {{ item.balance }}
          </span>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="dialog"
      max-width="600"
    >
      <v-card>
        <v-card-title class="d-flex justify-content-between">
          {{ $t("drugs_equipment_remaining") }}
          <v-spacer></v-spacer>
          <v-icon
            color="error"
            @click="dialog = false"
          >
            {{ icons.mdiClose }}
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-simple-table
            fixed-header
            dense
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    #
                  </th>
                  <th class="text-left">
                    {{ $t("receipt_number") }}
                  </th>
                  <th
                    class="text-left"
                    width="140px"
                  >
                    {{ $t("list") }}
                  </th>
                  <th class="text-left">
                    {{ $t("balance") }}
                  </th>
                  <th class="text-left">
                    {{ $t("unit") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in drugDataViewList"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.order_id }}</td>
                  <td>{{ item.drug_name }} ({{ item.drug_id }})</td>
                  <td>{{ item.amount }}</td>
                  <td>{{ item.drug_unit }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  mdiTimetable, mdiClockOutline, mdiPill, mdiClose,
  mdiFileExcelOutline,
} from '@mdi/js'
import DateFilters from '@/components/basicComponents/DateFilters.vue'
import Comeback from '../Comeback.vue'
import { i18n } from '@/plugins/i18n'
import { reportServiceSummary, removeComma } from '../useExcel'
import { sumdate } from '@/plugins/filters'
import customerGroup from '@/api/customer/customerGroup'
import veiwDrugReport from '@/api/viewDrugReport'

export default {
  components: {
    DateFilters,
    Comeback,
  },
  setup() {
    const dialog = ref(false)
    const drugDataViewList = ref([])
    const customerGroupSelect = ref('')
    const customerGroupList = ref([])
    const searchtext = ref('')
    const XLSX = require('xlsx')
    const payload = ref({})
    const loading = ref(false)
    const dataTableList = ref([])
    const columns = ref([
      { text: '#', value: 'number', width: 50 },
      { text: i18n.t('customer_code'), value: 'customer_id', width: 100 },
      { text: i18n.t('firstname_lastname'), value: 'customer_fullname', width: 200 },
      { text: i18n.t('gender'), value: 'customer_gender', width: 80 },
      { text: i18n.t('tel'), value: 'customer_tel', width: 110 },
      { text: i18n.t('course_list'), value: 'course_name', width: 300 },
      { text: i18n.t('type'), value: 'course_type', width: 120 },
      {
        text: i18n.t('amount'), value: 'balance', width: 120, align: 'end',
      },
      { text: i18n.t('unit'), value: 'course_unit', width: 120 },
      { text: i18n.t('last_used'), value: 'serving_date', width: 120 },
      { text: i18n.t('end_day'), value: 'serving_end', width: 120 },
    ])
    const options = ref({})
    const exportLoading = ref(false)
    const dataCourse = ref([])
    const dataDrug = ref([])
    const addPayload = (start, end) => {
      payload.value = {
        start,
        end,
        customer_group_id: customerGroupSelect.value,
        searchtext: searchtext.value,
        lang: i18n.locale,
      }
      serachReport(payload.value)
    }

    const serachReport = async payload => {
      loading.value = true
      const {
        datacourse,
        data,
        datadrug,
      } = await reportServiceSummary(payload)
      dataCourse.value = datacourse
      dataTableList.value = data
      dataDrug.value = datadrug
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    }

    customerGroup.customerGroupList({
      searchtext: '',
      lang: i18n.locale,
      per_page: '0',
      active_page: 1,
    }).then(res => {
      customerGroupList.value = res.data
      customerGroupList.value.unshift({
        customer_group_id: '',
        customer_group_name: i18n.t('all_customer_groups'),
      })
    })
    const showDetailDrugService = (cus_id, course_id) => {
      veiwDrugReport.viewDrugReportGet({
        customer_id_pri: cus_id,
        course_id_pri: course_id,
      }).then(res => {
        drugDataViewList.value = res
        dialog.value = true
      })
    }

    const exportExcel = async () => {
      exportLoading.value = true
      const { start, end } = payload.value
      const fileName = `${i18n.t('CourseBalanceSummary')}${i18n.t(
        'detail_at_day',
      )} ${i18n.t('since')} ${sumdate(start)} ${i18n.t(
        'to',
      )} ${sumdate(end)} .xlsx`

      // เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx xxx
      /*  รายงานวิเคราะห์รายการตรวจ */

      dataTableList.value = dataTableList.value.map(item => {
        delete item.customer_id_pri
        delete item.course_id
        delete item.course_id_pri

        return item
      })

      dataDrug.value = dataDrug.value.map(item => {
        delete item.course_id_pri

        return item
      })
      const dataExport = await removeComma(JSON.parse(JSON.stringify(dataTableList.value)))
      dataDrug.value = await removeComma(JSON.parse(JSON.stringify(dataDrug.value)))
      dataCourse.value = await removeComma(JSON.parse(JSON.stringify(dataCourse.value)))
      const Heading = [
        [
          `${i18n.t('CourseBalanceSummary')} ${i18n.t(
            'detail_at_day',
          )} ${i18n.t('since')} ${sumdate(start)} ${i18n.t(
            'to',
          )} ${sumdate(end)}  `,
        ],
        [
          '#',
          `${i18n.t('customer_code')}`,
          `${i18n.t('firstname_lastname')}`,
          `${i18n.t('gender')}`,
          `${i18n.t('tel')}`,
          `${i18n.t('group_customers')}`,
          `${i18n.t('status')}`,
          `${i18n.t('all_courses')}`,
          `${i18n.t('waiting_service')}`,
          `${i18n.t('use_service')}`,
          `${i18n.t('last_used')}`,
        ],
      ]

      const ws = XLSX.utils.aoa_to_sheet(Heading)
      XLSX.utils.sheet_add_json(ws, dataCourse.value, {
        header: [
          'number',
          'customer_id',
          'customer_fullname',
          'customer_gender',
          'customer_tel',
          'customer_group_name',
          'customer_status_name',
          'course_total',
          'course_wait',
          'course_use',
          'use_date',
        ],
        skipHeader: true,
        origin: -1,
      })
      const wb = XLSX.utils.book_new()
      const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 10 } }]
      const wscols = [
        { wch: 6 },
        { wch: 8 },
        { wch: 25 },
        { wch: 10 },
        { wch: 10 },
        { wch: 12 },
        { wch: 15 },
        { wch: 12 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 15 },
      ]
      ws['!merges'] = merge
      ws['!cols'] = wscols
      XLSX.utils.book_append_sheet(
        wb,
        ws,
        `${i18n.t('CourseBalanceSummary')}`,
      )

      /*  SHEET 2 */
      const Heading2 = [
        [
          `${i18n.t('list_of_remaining_procedures')} ${i18n.t(
            'detail_at_day',
          )} ${i18n.t('since')} ${sumdate(start)} ${i18n.t(
            'to',
          )} ${sumdate(end)}  `,
        ],
        [
          '#',
          `${i18n.t('customer_code')}`,
          `${i18n.t('firstname_lastname')}`,
          `${i18n.t('gender')}`,
          `${i18n.t('tel')}`,
          `${i18n.t('course_list')}`,
          `${i18n.t('type')}`,
          `${i18n.t('amount')}`,
          `${i18n.t('unit')}`,
          `${i18n.t('last_used')}`,
          `${i18n.t('end_day')}`,
        ],
      ]

      const ws2 = XLSX.utils.aoa_to_sheet(Heading2)
      XLSX.utils.sheet_add_json(ws2, dataExport, {
        header: [
          'number',
          'customer_id',
          'customer_fullname',
          'customer_gender',
          'customer_tel',
          'course_name',
          'course_type',
          'balance',
          'course_unit',
          'serving_date',
          'serving_end',
        ],
        skipHeader: true,
        origin: -1,
      })

      const merge2 = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 10 } }]
      const wscols2 = [
        { wch: 6 },
        { wch: 8 },
        { wch: 20 },
        { wch: 10 },
        { wch: 10 },
        { wch: 12 },
        { wch: 15 },
        { wch: 12 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 15 },
      ]
      ws2['!merges'] = merge2
      ws2['!cols'] = wscols2
      XLSX.utils.book_append_sheet(
        wb,
        ws2,
        `${i18n.t('list_of_remaining_procedures')}`,
      )

      /* พิมร์ files */
      /*  SHEET 3 */
      const Heading3 = [
        [
          `${i18n.t('drugs_equipment_remaining')} ${i18n.t(
            'detail_at_day',
          )} ${i18n.t('since')} ${sumdate(start)} ${i18n.t(
            'to',
          )} ${sumdate(end)}  `,
        ],
        [
          '#',
          `${i18n.t('receipt_number')}`,
          `${i18n.t('firstname_lastname')}`,
          `${i18n.t('gender')}`,
          `${i18n.t('tel')}`,
          `${i18n.t('course')}`,
          `${i18n.t('list_drug_equipment')}`,
          `${i18n.t('balance')}`,
          `${i18n.t('unit')}`,
          `${i18n.t('last_used')}`,
        ],
      ]

      const ws3 = XLSX.utils.aoa_to_sheet(Heading3)
      XLSX.utils.sheet_add_json(ws3, dataDrug.value, {
        header: [
          'number',
          'order_id',
          'customer_fullname',
          'customer_gender',
          'customer_tel',
          'course_name',
          'drug_name',
          'servingdetail_book_amount',
          'drug_unit',
          'servingdetail_book_modify',
        ],
        skipHeader: true,
        origin: -1,
      })

      const merge3 = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 10 } }]
      const wscols3 = [
        { wch: 6 },
        { wch: 8 },
        { wch: 20 },
        { wch: 10 },
        { wch: 10 },
        { wch: 12 },
        { wch: 15 },
        { wch: 12 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 15 },
      ]
      ws3['!merges'] = merge3
      ws3['!cols'] = wscols3
      XLSX.utils.book_append_sheet(
        wb,
        ws3,
        `${i18n.t('drugs_equipment_remaining')}`,
      )

      /* พิมร์ files */
      XLSX.writeFile(wb, fileName)

      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    return {
      customerGroupSelect,
      customerGroupList,
      searchtext,
      XLSX,
      payload,
      loading,
      dataTableList,
      columns,
      options,
      exportLoading,
      dialog,
      drugDataViewList,
      icons: {
        mdiTimetable, mdiClockOutline, mdiPill, mdiClose,
      },
      addPayload,
      exportExcel,
      showDetailDrugService,
      mdiFileExcelOutline,
    }
  },
}
</script>

import refreshToken from '@/plugins/refresh-token'
import { api } from './main'

const viewDrugReportGet = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'service/viewdrug',
      body,
    })
    .then(res => (res.data || []))
    .catch(err => {
      console.error('get detail drug : ', err)

      return []
    })

  return response
}
export default { viewDrugReportGet }
